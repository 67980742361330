import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import logo from './sl.png';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: `53rem`,
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,

      }}
    >
      <header>
          <Link             style={{boxShadow: `none`,textDecoration: `none`,color: `inherit`,}} to={`/`}><img src={logo} style={{width: '200px'}} alt="Logo" /></Link>
      </header>
      <main>{children}</main>
      <footer>
      		<p>Tháng trước quên gia hạn tên miền, giờ sololeveling.ga đã hoạt động trợ lại, thành thật xin lỗi 😭</p>
		<p><b>Sưu tầm từ nhóm dịch:</b><br/> 
		Kuro's Hobby & MTO (<a href="https://truyen.fascans.com/manga/solo-leveling">Truyen.fascans.com</a>) - (chap 1-20), <br/>
		Lê Tâm (<a href="https://www.facebook.com/SLletam">Fanpage Facebook</a>) - (Chap 21-107),  <br/>
		Phong Lăng (<a href="https://blogtruyen.vn/19704/solo-leveling-pl">Blogtruyen.vn</a>) - (Chap 108-110),  <br/>
		Tivi webtoon (<a href="https://play.google.com/store/apps/details?id=tivi.vina.thecomics">Tivi webtoon</a>) - (Chap 124-129,163,164),  <br/>
		Delay Team (<a href="https://qmanga.net/toi-thang-cap-mot-minh-ss2.html">Qmanga.net</a>, <a href="https://vlogtruyen.net/toi-thang-cap-mot-minh-ss2.html">Vlogtruyen.net</a>) - (Chap 111-123, 130-140, 142-147, 149-159, 166-170),<br/>
		Phong Lăng & My Friends / SuperNova (<a href="https://mangadex.org/group/11282/phong-l-ng-my-friends">Mangadex.org</a>, <a href="https://cuutruyen.net/mangas/112">Cuutruyen.net</a>) - (Chap 141,148, 160-162, 165).</p>
        <p>© {new Date().getFullYear()}. </p>
      </footer>
    </div>
  )
}

export default Layout
